import { AsYouType } from 'libphonenumber-js';

const formatPhone = (phoneNumber) => {
  if (phoneNumber) {
    let numberFormatted = phoneNumber.replace(/([^0-9])/g, '');
    numberFormatted = `+${numberFormatted}`;
    return new AsYouType().input(numberFormatted);
  }
  return null;
};

export { formatPhone };
