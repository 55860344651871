<template>
  <NgInput class="ng-phone-input" v-bind="$attrs" v-on="$listeners">
    <template #default="{ inputAttrs }">
      <v-text-field
        v-bind="inputAttrs"
        :type="inputAttrs.type !== 'hidden' ? 'tel' : 'hidden'"
        :placeholder="inputAttrs.placeholder || '+00 000 000 00 00 000'"
        :value="innerValue"
        @input="onInput"
        ref="textfield"
      >
        <template v-for="(_, slot) of $slots" v-slot:[slot]>
          <slot :name="slot"></slot>
        </template>
      </v-text-field>
    </template>
  </NgInput>
</template>

<script>
import NgInput from './NgInput.vue';
import { formatPhone } from '../../js/phoneFormatter';

const correctCaretPos = ({ newVal, val, currentPos, formattingSymbols }) => {
  // TODO: doesn't work when letter was typed in val
  const regexp = new RegExp(`[${formattingSymbols.join(',')}]`, 'g');
  const essentialValLeft = val.slice(0, currentPos).replace(regexp, '');

  let indexEssential = 0;
  let newPos = 0;
  while (indexEssential < essentialValLeft.length && newPos < newVal.length) {
    if (newVal[newPos] === essentialValLeft[indexEssential]) {
      indexEssential += 1;
    }
    newPos += 1;
  }

  return newPos;
};

export default {
  name: 'NgPhoneInput',
  components: {
    NgInput,
  },
  data() {
    return {
      innerValue: undefined,
    };
  },
  computed: {
    inputNode() {
      return this.$refs.textfield.$refs.input;
    },
  },
  created() {
    this.innerValue = formatPhone(this.$attrs.value);
  },
  methods: {
    onInput(val) {
      const numberFormatted = formatPhone(val);
      const caretPosition = this.inputNode.selectionStart;
      let newCaretPosition;

      if (numberFormatted !== this.innerValue) {
        // final value has changed
        newCaretPosition = correctCaretPos({
          newVal: numberFormatted,
          val,
          currentPos: caretPosition,
          formattingSymbols: [' '],
        });
      } else {
        newCaretPosition = caretPosition === 0 ? 1 : caretPosition - 1;
      }

      // set unformatted value to update v-text-field lazyValue
      // in some cases formatting (e.g. 23 -> 23f ->23) doesn't change previous value
      this.innerValue = val;

      // then set formatted value and emit input on next tick
      this.$nextTick(() => {
        this.innerValue = numberFormatted;
        this.$emit('input', this.innerValue);

        // set corrected caret position
        this.$nextTick(() => {
          this.inputNode.selectionStart = this.inputNode.selectionEnd =
            newCaretPosition;
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
